import { sanityClient } from "../sanityClient";
import imageUrlBuilder from "@sanity/image-url";

export async function getHeaderData() {
  try {
    const temp = await sanityClient.fetch(`*[_type == "header"][0]{
      companylogo{
        asset->{
          ...,
          metadata
				}
      },
      headerbuttontext
  }`);

    const builder = imageUrlBuilder(sanityClient);
    let imageProps = builder.image(temp?.companylogo).url();

    return {
      companylogo: imageProps,
      headerbuttontext: temp?.headerbuttontext,
    };
  } catch (e) {
    console.log("Error While Fetching Header Data From Sanity", e);
  }
}

export async function getLandingPageData() {
  let temp = await sanityClient.fetch(`*[_type == "landingPageSection"][0]{
      landingPageTitle,
      landingPageDescription,
      landingPageImage {
        asset->{
          ...,
          metadata
				}
      }
    }`);
  let landingPageImage = imageUrlBuilder(sanityClient);

  return {
    landingPageTitle: temp?.landingPageTitle,
    landingPageDescription: temp?.landingPageDescription,
    landingPageImage: landingPageImage.image(temp?.landingPageImage),
  };
}

export async function getFeaturedCompanies() {
  let temp = await sanityClient.fetch(`*[_type == "featuredCompanies"][0]{
    featuredCompaniesTitle,
    featuredCompaniesLogo
  }`);

  let FCLogos = imageUrlBuilder(sanityClient);

  let TempfeaturedCompaniesLogos = temp?.featuredCompaniesLogo;

  let featuredCompaniesLogos = TempfeaturedCompaniesLogos.map((item) => {
    return {
      name: item.name,
      logo: FCLogos.image(item.logo).url(),
      url: item.url,
    };
  });

  return {
    featuredCompaniesTitle: temp?.featuredCompaniesTitle,
    featuredCompaniesLogo: featuredCompaniesLogos,
  };
}

export async function getHowItWorkSteps() {
  let temp = await sanityClient.fetch(`*[_type == "howItWorkSection"][0]{
      howItWorkSteps,
      howItWorkTitle,
      howItWorkMiniTitle,
      howItWorkLastMiniSlug
    }`);

  return temp;
}

export async function getTruspoDifference() {
  let temp = await sanityClient.fetch(`*[_type == "truspoDifferenceSection"]{
    point,
    pointDescription,
    thatPointMatchTruspo,
    thatPointMatchIndeed,
    thatPointMatchGlassdoor,
    thatPointMatchSurvey
    }`);
  return temp;
}

export async function getYoutubeSection() {
  let temp = await sanityClient.fetch(`*[_type == "youtubeSection"][0]{
    youtubeSectionTitle,
    youtubeSectionDescription,
    youtubeSectionButtonText,
    youtubeSectionChannelLink,
    youtubeSectionVideoLink  
  }`);
  return temp;
}

export async function getFaqSection() {
  let temp = await sanityClient.fetch(`*[_type == "truspoFaqSection"]{
    truspoFaqRank,
    truspoFaqQuestion,
    truspoFaqAnswer  
  }`);

  return temp;
}

export async function getFooterSection() {
  let temp = await sanityClient.fetch(`*[_type == "truspoFooterSection"][0]{
      footerSectionLogo{
        asset->{
          ...,
          metadata
				}
      },
      footerSectionMiddleTitle,
      footerSectionButtonText,
      footerSectionBottomCopyrightText,
      footerSectionColumns[]{
        columnTitle,
        columnList[]->{footerNavItemText,footerNavItemType,footerNavItemLink}
      }
    }`);

  const builder = imageUrlBuilder(sanityClient);
  let imageProps = builder.image(temp?.footerSectionLogo).url();

  return {
    footerSectionLogo: imageProps,
    footerSectionMiddleTitle: temp?.footerSectionMiddleTitle,
    footerSectionButtonText: temp?.footerSectionButtonText,
    footerSectionBottomCopyrightText: temp?.footerSectionBottomCopyrightText,
    footerSectionColumns: temp?.footerSectionColumns,
  };
}

export async function getBlogSection() {
  let temp = await sanityClient.fetch(`*[_type == "blogSection"]{
      blogTitle,
      blogSlug,
      blogThumbnail{
        asset->{
          ...,
          metadata
				}
      }
    }`);
  return temp;
}

export async function getSingleBlogSection(blog_slug) {
  const temp =
    await sanityClient.fetch(`*[_type == "blogSection" && blogSlug.current == "${blog_slug}"][0]{
      blogTitle,
      blogSlug,
      blogThumbnail,
      blogDescription
    }`);
  return temp;
}

export async function getInformationPage(pageName) {
  const temp =
    await sanityClient.fetch(`*[_type == "informationPages" && pageName == "${pageName}"][0]{
    pageName,
    pageDescription,
    _updatedAt
    }`);

  return temp;
}

export async function getRestrictedMails() {
  let temp = await sanityClient.fetch(`*[_type == "restrictedMails"][0]{
    listOfRestrictedMails
    }`);

  return temp;
}

export async function getUserRoles({ username, password }) {
  let temp =
    await sanityClient.fetch(`*[_type == "userRoles" && username == "${username}" && password == "${password}"]{
      fullname,
      roleType,
    }`);
  return temp;
}

export async function getEmailFrom() {
  let temp = await sanityClient.fetch(`*[_type == "emailConfiguration"][0]{
      emailSentFrom
    }`);
  return temp;
}
