import { useState, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./style/homepage.module.css";
import Logo from "../images/Truspo-white.png";
import ReviewPopup from "../components/popups/reviewPopup";
import FAQS from "../components/faqs";
import {
  getHeaderData,
  getLandingPageData,
  getFeaturedCompanies,
  getHowItWorkSteps,
  getTruspoDifference,
  getYoutubeSection,
  getFaqSection,
} from "../sanity_api/sanity_apis";

import { Spinner } from "react-bootstrap";

export default function HomePage() {
  // UseStates to Save Data Come from Sanity Studio
  // Save Data for NavBar Button
  const [buttonText, setButtonText] = useState("");
  // Save Data for LandingPage Section
  const [landingPageTitle, setLandingPageTitle] = useState("");
  const [landingPageDescription, setLandingPageDescription] = useState("");
  const [landingPageImage, setLandingPageImage] = useState();
  // Save Data for Featured Companies Section
  const [featuredCompaniesTitle, setFeaturedCompaniesTitle] = useState();
  const [featuredCompaniesLogos, setFeaturedCompaniesLogos] = useState([]);
  // Save Data for How It Works Section
  const [howItWorkMiniTitle, setHowItWorkMiniTitle] = useState("");
  const [howItWorkTitle, setHowItWorkTitle] = useState("");
  const [howItWorkSteps, setHowItWorkSteps] = useState([]);
  const [howItWorkMiniSlug, setHowItWorkMiniSlug] = useState("");
  // Save Data for Truspo Difference
  const [truspoDifferencePoint, setTruspoDifferencePoint] = useState();
  // Save Data for Youtube Section
  const [youtubeSection, setYoutubeSection] = useState();
  // Save Data for FAQ Section
  const [truspoFaq, setTruspoFaq] = useState();

  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    async function callSanityAPI() {
      // Call Sanity API
      let headerData = await getHeaderData();
      let landingPageData = await getLandingPageData();
      let featuredCompaniesData = await getFeaturedCompanies();
      let howitworkStepsData = await getHowItWorkSteps();
      let truspoDifferenceData = await getTruspoDifference();
      let youtubeVideoSection = await getYoutubeSection();
      let truspoFaqData = await getFaqSection();
      // Save Sanity Data into States
      setButtonText(headerData?.headerbuttontext);
      setLandingPageTitle(landingPageData?.landingPageTitle);
      setLandingPageDescription(landingPageData?.landingPageDescription);
      setLandingPageImage(landingPageData?.landingPageImage);
      setFeaturedCompaniesTitle(featuredCompaniesData?.featuredCompaniesTitle);
      setFeaturedCompaniesLogos(featuredCompaniesData?.featuredCompaniesLogo);
      setHowItWorkMiniTitle(howitworkStepsData?.howItWorkMiniTitle);
      setHowItWorkTitle(howitworkStepsData?.howItWorkTitle);
      setHowItWorkSteps(howitworkStepsData?.howItWorkSteps);
      setHowItWorkMiniSlug(howitworkStepsData?.howItWorkLastMiniSlug);
      setYoutubeSection(youtubeVideoSection);

      // Truspo Difference Point Sorting Before Saving Into State
      const sortedTruspoDifferencePoint = truspoDifferenceData?.sort((a, b) => {
        const order = [
          "Verified",
          "Non-Identifiable Question",
          "Historical Reviews",
          "Industry Insights",
          "No Reviews Removal",
        ];
        return order.indexOf(a.point) - order.indexOf(b.point);
      });
      setTruspoDifferencePoint(sortedTruspoDifferencePoint);

      // Truspo FAQ Sorting Before Saving Into State
      const sortedTruspoFaqData = truspoFaqData?.sort((a, b) => {
        let order = Array.from(
          { length: truspoFaqData.length },
          (v, i) => i + 1
        );
        return order.indexOf(a.truspoFaqRank) - order.indexOf(b.truspoFaqRank);
      });
      setTruspoFaq(sortedTruspoFaqData);
      setIsVisible(false);
    }
    callSanityAPI();
  }, []);
  return (
    <>
      <Header FaqData={truspoFaq} buttonVisible={true} />
      {/* PreLoader */}
      <div
        className={styles.overlays}
        style={{ display: isVisible ? "block" : "none" }}
      >
        <div className={styles.overlaysSpinner}>
          <Spinner
            animation="grow"
            id="overlays"
            style={{ color: "#f1642b", height: "100px", width: "100px" }}
          />
        </div>
      </div>

      {/* LandingPage Component */}
      <div className={styles.LandingPageMain}>
        <Container>
          <Row>
            <Col>
              <div className={styles.LandingPageText}>
                <h1>{landingPageTitle}</h1>
                <p>{landingPageDescription}</p>
                <ReviewPopup buttonText={buttonText} faqData={truspoFaq} />
              </div>
            </Col>
            <Col className="d-none d-sm-block">
              {/* <div className={styles.landingPageImage}></div> */}
              <img
                alt={landingPageTitle}
                className={styles.landingPageImage}
                src={landingPageImage}
              />
            </Col>
          </Row>
        </Container>
      </div>

      {/* Featured Companies */}
      <div className={styles.featuredCompaniesMain}>
        <h3>{featuredCompaniesTitle}</h3>
        <Container>
          <Row>
            {featuredCompaniesLogos.map((item) => {
              return (
                <Col>
                  <a href={item?.url} target="_blank">
                    <img alt={item?.name} src={item.logo} />
                  </a>
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>

      {/* How It Works */}
      <div id="howitworks" className={styles.howitworksMain}>
        <Container>
          <Row>
            <Col lg={6} md={6} sm={12} xs={12}>
              <div className={styles.howitworksText}>
                <h5>{howItWorkMiniTitle}</h5>
                <h1>{howItWorkTitle}</h1>
                <iframe
                  title={howItWorkMiniTitle}
                  height={450}
                  width={500}
                  src="https://lottie.host/embed/0d5188b3-39a0-4292-ae0b-91dbfba86879/AwXmsTnTlP.json"
                ></iframe>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <div className={styles.howitworksSteps}>
                {howItWorkSteps.map((step) => {
                  return (
                    <div className={styles.howitworksStepCon}>
                      <span class="material-symbols-outlined">
                        {step.howItWorkStepIcon}
                      </span>
                      <h2>{step.howItWorkStepTitle}</h2>
                      <p>{step.howItWorkStepDescription}</p>
                    </div>
                  );
                })}
                <div className={styles.howitworksStepsBtnSec}>
                  <ReviewPopup buttonText={buttonText} faqData={truspoFaq} />
                  <p>{howItWorkMiniSlug}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* The Truspo Difference  */}
      <div className={styles.truspoDifferenceMain}>
        <h1>The Truspo Difference</h1>

        <Container className={styles.centeredContainer}>
          <div className={styles.tableContainer}>
            <table>
              <thead>
                <tr className={styles.truspoDifferenceTableHeader}>
                  <td width={"40%"}></td>
                  <td>
                    <img
                      alt="Truspo Difference"
                      src={Logo}
                      height={"60px"}
                      width={"180px"}
                    />
                  </td>
                  <td>Indeed</td>
                  <td>Glassdoor</td>
                  <td>Internal Surveys</td>
                </tr>
              </thead>
              <tbody>
                {truspoDifferencePoint?.map((point) => {
                  function decodeHtmlEntity(emoji) {
                    const parser = new DOMParser();
                    const myDecodingParse = parser.parseFromString(
                      `<!doctype html><body>${emoji}`,
                      "text/html"
                    ).body.textContent;
                    return myDecodingParse;
                  }
                  return (
                    <tr className={styles.truspoDifferenceTableRow}>
                      <td width={"40%"} style={{ textAlign: "left" }}>
                        <b>{point?.point}</b>
                        <br /> {point?.pointDescription}
                      </td>
                      <td>{decodeHtmlEntity(point?.thatPointMatchTruspo)}</td>
                      <td>{decodeHtmlEntity(point?.thatPointMatchIndeed)}</td>
                      <td>
                        {decodeHtmlEntity(point?.thatPointMatchGlassdoor)}
                      </td>
                      <td>{decodeHtmlEntity(point?.thatPointMatchSurvey)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Container>
      </div>

      {/* Youtube Video Section */}
      <div className={styles.youtubeVideoSection}>
        <Container>
          <Row className={styles.youtubeVideoSectionRow}>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <div className={styles.youtubeSectionText}>
                <h2>{youtubeSection?.youtubeSectionTitle}</h2>
                <p>{youtubeSection?.youtubeSectionDescription}</p>
                <button
                  onClick={() => {
                    window.open(
                      youtubeSection?.youtubeSectionChannelLink,
                      "_blank"
                    );
                  }}
                >
                  {youtubeSection?.youtubeSectionButtonText}
                </button>
              </div>
            </Col>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <div className={styles.youtubeSectionVideo}>
                <iframe
                  title={youtubeSection?.youtubeSectionTitle}
                  style={{ borderRadius: "8px" }}
                  width="580px"
                  height="375px"
                  src={youtubeSection?.youtubeSectionVideoLink}
                ></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* FAQ'S Section */}
      <FAQS widthSize="50%" FaqData={truspoFaq} />
      <Footer FaqData={truspoFaq} buttonVisible={true} />
    </>
  );
}
