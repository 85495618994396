import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Footer from "../components/footer";
import Header from "../components/header";
import styles from "./style/contactpage.module.css";
import { getFaqSection } from "../sanity_api/sanity_apis";
export default function ContactPage() {
  const [truspoFaq, setTruspoFaq] = useState();

  useEffect(() => {
    async function CallSanityApi() {
      let truspoFaqData = await getFaqSection();

      // Truspo FAQ Sorting Before Saving Into State
      const sortedTruspoFaqData = truspoFaqData?.sort((a, b) => {
        let order = Array.from(
          { length: truspoFaqData.length },
          (v, i) => i + 1
        );
        return order.indexOf(a.truspoFaqRank) - order.indexOf(b.truspoFaqRank);
      });
      setTruspoFaq(sortedTruspoFaqData);
    }
    CallSanityApi();
  });
  return (
    <>
      <Header FaqData={truspoFaq} buttonVisible={true} />
      <Container>
        <div className={styles.contactPageMain}>
          <h1>Contact Us Page</h1>
        </div>
      </Container>
      <Footer FaqData={truspoFaq} buttonVisible={true}/>
    </>
  );
}
