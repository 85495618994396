import { useRef, useState } from "react";
import { Container } from "react-bootstrap";
import styles from "../style/login.module.css";
import LogoImg from "../../images/Truspo-logo.png";
import { getUserRoles } from "../../sanity_api/sanity_apis";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";

export default function LoginPage() {
  const navigate = useNavigate();

  const [errorNote, setErrorNote] = useState("");
  const username = useRef("");
  const password = useRef("");
  const [eyeIcon, setEyeIcon] = useState("visibility_off");

  const handleSubmit = async () => {
    if (username?.current?.value === "" || password?.current?.value === "") {
      setErrorNote("Please Enter Username and Password");
    } else {
      let res = await getUserRoles({
        username: username?.current?.value,
        password: password?.current?.value,
      });

      if (res.length > 0) {
        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify({
            fullname: res[0].fullname,
            roleType: res[0].roleType,
          }),
          process.env.REACT_APP_JWT_SECRET
        ).toString();
        localStorage.setItem("adminSession", encryptedData);
        navigate("/dashboard");
      } else {
        setErrorNote("Invalid Credentials!!");
      }
    }
  };
  return (
    <div className={styles.loginPageMain}>
      <img
        onClick={() => {
          navigate("/");
        }}
        alt="Truspo Logo"
        style={{ cursor: "pointer" }}
        src={LogoImg}
        height="70px"
        width="230px"
      />
      <Container>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className={styles.loginPageCon}>
            <h1>Administrator Login</h1>
            <input ref={username} placeholder="Enter Username" />
            <input
              type={eyeIcon === "visibility" ? "text" : "password"}
              ref={password}
              placeholder="Enter Password"
            />
            <button
              onClick={() => {
                if (eyeIcon === "visibility") {
                  setEyeIcon("visibility_off");
                } else {
                  setEyeIcon("visibility");
                }
              }}
              class="material-symbols-outlined"
            >
              {eyeIcon}
            </button>
            <button onClick={handleSubmit}>Login</button>
            <p style={{ color: "red", padding: "0px 10px" }}>{errorNote}</p>
          </div>
        </div>
      </Container>
    </div>
  );
}
