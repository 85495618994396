import { Offcanvas, Button } from "react-bootstrap";
import { useState } from "react";
import styles from "../style/admin.module.css";
import LogoImg from "../../images/Truspo-logo.png";
import { useNavigate } from "react-router-dom";

export default function Navbar({ setCurrentTab }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  const signOut = () => {
    localStorage.removeItem("adminSession");
    window.location.reload();
  };
  return (
    <div className={styles.navbarMain}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button onClick={handleShow} className={styles.menuButton}>
          Menu
        </Button>
      </div>
      <div className={styles.navBarItem}>
        <img
          onClick={() => {
            navigate("/dashboard");
            setCurrentTab("dashboard");
          }}
          style={{ cursor: "pointer" }}
          src={LogoImg}
          height="70px"
          width="230px"
          alt="Logo"
        />

        <ul>
          <li
            onClick={() => {
              setCurrentTab("dashboard");
            }}
          >
            Dashboard
          </li>
          <li
            onClick={() => {
              setCurrentTab("userManagment");
            }}
          >
            User Management
          </li>
          <li
            onClick={() => {
              setCurrentTab("companies");
            }}
          >
            Companies
          </li>
          <li
            onClick={() => {
              setCurrentTab("emailSchedule");
            }}
          >
            Email Schedule
          </li>
          <li onClick={signOut}>SignOut</li>
        </ul>
      </div>

      <Offcanvas show={show} onHide={handleClose} placement="start">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <img src={LogoImg} height="70px" width="230px" alt="Logo" />

          <div>
            <ul className={styles.offcanvasMenu}>
              <li
                onClick={() => {
                  setCurrentTab("dashboard");
                }}
              >
                Dashboard
              </li>
              <li
                onClick={() => {
                  setCurrentTab("userManagment");
                }}
              >
                User Management
              </li>
              <li
                onClick={() => {
                  setCurrentTab("companies");
                }}
              >
                Companies
              </li>
              <li
                onClick={() => {
                  setCurrentTab("emailSchedule");
                }}
              >
                Email Schedule
              </li>
              <li onClick={signOut}>SignOut</li>
            </ul>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}
