import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

export function PieChart({ data }) {
  ChartJS.register(ArcElement, Tooltip, Legend);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "20px 0px",
        }}
      >
        <h6>
          <b>Total Number of Users</b>
        </h6>
        <h6>20/03/2024 02:34 PM</h6>
      </div>
      <Pie
        // options={...}
        data={data}
        // {...props}
      />
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "20px 0px",
          }}
        >
          <h6>
            <b>Today Users</b>
          </h6>
          <h6>332</h6>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "20px 0px",
          }}
        >
          <h6>
            <b>Yesterday Users</b>
          </h6>
          <h6>332</h6>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "20px 0px",
          }}
        >
          <h6>
            <b>Today Users</b>
          </h6>
          <h6>332</h6>
        </div>
      </div>
    </>
  );
}
