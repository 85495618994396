import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./style/footer.module.css";
import ReviewPopup from "./popups/reviewPopup";
import { useNavigate } from "react-router-dom";
import { getFooterSection, getBlogSection } from "../sanity_api/sanity_apis";

export default function Footer({ FaqData, buttonVisible }) {
  let navigate = useNavigate();
  const [footerData, setFooterData] = useState();
  const [blogHeadline, setBlogHeadline] = useState();

  useEffect(() => {
    const tickerList = document.querySelector(`.${styles.tickerList}`);
    const tickerItems = document.querySelectorAll(`.${styles.tickerList} li`);

    const handleMouseEnter = () => {
      tickerList.style.animationPlayState = "paused";
    };

    const handleMouseLeave = () => {
      tickerList.style.animationPlayState = "running";
    };

    tickerItems.forEach((item) => {
      item.addEventListener("mouseenter", handleMouseEnter);
      item.addEventListener("mouseleave", handleMouseLeave);
    });

    // Clean up event listeners on component unmount
    return () => {
      tickerItems.forEach((item) => {
        item.removeEventListener("mouseenter", handleMouseEnter);
        item.removeEventListener("mouseleave", handleMouseLeave);
      });
    };
  }, []);

  useEffect(() => {
    async function CallSanityAPI() {
      let res = await getFooterSection();
      let blogHeadline = await getBlogSection();

      setBlogHeadline(blogHeadline);
      setFooterData(res);
    }
    CallSanityAPI();
  }, []);

  return (
    <div className={styles.footerMain}>
      <Container>
        {/* Section#1 */}
        <div className={styles.footerSection1}>
          <Row>
            <Col lg={4} md={12} sm={12} xs={12}>
              <div className={styles.footerSection1Logo}>
                <img
                  onClick={() => {
                    navigate("/");
                  }}
                  alt="truspo Logo"
                  src={footerData?.footerSectionLogo}
                  className={styles.footerSection1LogoObj}
                />
              </div>
            </Col>
            <Col lg={4} md={12} sm={12} xs={12}>
              <div className={styles.footerSection1Text}>
                <h3>{footerData?.footerSectionMiddleTitle}</h3>
              </div>
            </Col>
            <Col lg={4} md={12} sm={12} xs={12}>
              <div className={styles.footerSection1Btn}>
                {buttonVisible ? (
                  <ReviewPopup
                    buttonText={footerData?.footerSectionButtonText}
                    faqData={FaqData}
                  />
                ) : (
                  <></>
                )}
              </div>
            </Col>
          </Row>
        </div>

        {/* Section#2 */}
        <div className={styles.footerSection2}>
          <Row className="mx-0">
            <Col lg={2} md={4} sm={12} xs={12} className="px-0">
              <div className={styles.newsTickerStatic}>
                <p>Latest Resources</p>
              </div>
            </Col>
            <Col lg={10} md={8} sm={12} xs={12} className="px-0">
              <div className={styles.newsTicker}>
                <ul className={styles.tickerList}>
                  {blogHeadline?.map((blog) => {
                    return (
                      <li
                        onClick={() => {
                          navigate(`/blogs/${blog?.blogSlug?.current}`);
                        }}
                      >
                        {blog?.blogTitle}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Col>
          </Row>
        </div>

        {/* Section#3 */}
        <div className={styles.footerSection3}>
          <Row>
            {footerData?.footerSectionColumns.map((col) => {
              return (
                <Col lg={3} md={3} sm={6} xs={6}>
                  <div>
                    <b>{col?.columnTitle}</b>
                    <ul>
                      {col?.columnList?.map((colItem) => {
                        return (
                          <>
                            {colItem?.footerNavItemType === "text" ? (
                              <li>
                                <a href={colItem?.footerNavItemLink}>
                                  {colItem?.footerNavItemText}
                                </a>
                              </li>
                            ) : (
                              <li>
                                <button
                                  onClick={() => {
                                    navigate(colItem?.footerNavItemLink);
                                  }}
                                >
                                  {colItem?.footerNavItemText}
                                </button>
                              </li>
                            )}
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>

        {/* Section#4 */}
        <div className={styles.footerSection4}>
          <p>{footerData?.footerSectionBottomCopyrightText}</p>
        </div>
      </Container>
    </div>
  );
}
