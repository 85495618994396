import { useEffect, useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Container, Row, Col } from "react-bootstrap";
import styles from "../style/blogs.module.css";
import { getBlogSection, getFaqSection } from "../../sanity_api/sanity_apis";
import imageUrlBuilder from "@sanity/image-url";
import { sanityClient } from "../../sanityClient";
import { Spinner } from "react-bootstrap";

export default function BlogsPage() {
  const [blogData, setBlogData] = useState();
  const [isVisible, setIsVisible] = useState(true);
  const [truspoFaq, setTruspoFaq] = useState();

  useEffect(() => {
    async function SanityApiCall() {
      let blogData = await getBlogSection();
      setBlogData(blogData);

      let truspoFaqData = await getFaqSection();
      //  Truspo FAQ Sorting Before Saving Into State
      const sortedTruspoFaqData = truspoFaqData?.sort((a, b) => {
        let order = Array.from(
          { length: truspoFaqData.length },
          (v, i) => i + 1
        );
        return order.indexOf(a.truspoFaqRank) - order.indexOf(b.truspoFaqRank);
      });
      setTruspoFaq(sortedTruspoFaqData);
      setIsVisible(false);
    }
    SanityApiCall();
  }, []);

  return (
    <>
      <Header FaqData={truspoFaq}  buttonVisible={true}/>
      {/* PreLoader */}
      <div
        className={styles.overlays}
        style={{ display: isVisible ? "block" : "none" }}
      >
        <div className={styles.overlaysSpinner}>
          <Spinner
            animation="grow"
            id="overlays"
            style={{ color: "#f1642b", height: "100px", width: "100px" }}
          />
        </div>
      </div>
      <div className={styles.blogPageMain}>
        <div className={styles.blogTitleSection}>
          <h1>Blogs</h1>
        </div>
        <Container>
          <div className={styles.blogCardSection}>
            <Row className="mx-0">
              {blogData?.map((blog) => {
                const builder = imageUrlBuilder(sanityClient);
                const thumbnailImage = builder.image(blog?.blogThumbnail).url();
                return (
                  <Col lg={4} md={4} sm={6} xs={12}>
                    <div
                      style={{
                        border: "1px solid #f6e7e4",
                        borderRadius: "8px",
                        margin: "20px 0px",
                      }}
                    >
                      <div className={styles.blogCardImage}>
                        <img
                          src={thumbnailImage}
                          alt={blog?.blogTitle}
                          width={"100%"}
                          height={"300px"}
                        />
                      </div>
                      <div className={styles.blogCardText}>
                        <h4>{blog?.blogTitle}</h4>
                        <p>
                          <a href={`/blogs/${blog?.blogSlug?.current}`}>
                            Continue Reading
                          </a>
                        </p>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </Container>
      </div>
      <Footer FaqData={truspoFaq}  buttonVisible={true}/>
    </>
  );
}
