import { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import styles from "../style/reviewPopup.module.css";
import FAQS from "../faqs";
import { getRestrictedMails } from "../../sanity_api/sanity_apis";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

export default function ReviewPopup({ buttonText, faqData }) {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const [emailInputIsDisable, setEmailInputIsDisable] = useState(false);
  const [codeInputIsDisable, setCodeInputIsDisable] = useState(false);
  const [verifyButtonIsDisable, setVerifyButtonIsDisable] = useState(false);
  const [resentEmailCounter, setResentEmailCounter] = useState(0);
  const [SpinnerVisible, setSpinnerVisible] = useState(false);

  const [submitButtonType, setSubmitButtonType] = useState("email");
  const [resentButtonText, setResentButtonText] = useState("Resend");
  const [errorNote, setErrorNote] = useState(
    "Remember: A verification code will be sent to your work email."
  );
  const [successNote, setSuccessNote] = useState("");

  const workingEmail = useRef("");
  const verificationCode = useRef("");
  const [companyName, setCompanyName] = useState(`https://`);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setSubmitButtonType("email");
    setEmailInputIsDisable(false);
    setCodeInputIsDisable(false);
    // setCompanyDefaultValue("https://");
    companyName.current.value = "https://";
    changeVisibility("successNote", "none");
    setErrorNote(
      "Remember: A verification code will be sent to your work email."
    );
    setSuccessNote("");
    setResentEmailCounter(0);
    setVerifyButtonIsDisable(false);
    setCodeInputIsDisable(false);
  };

  function changeVisibility(id, cssValue) {
    const vf = document.getElementById(id);
    vf.style.display = cssValue;
  }

  const countdownDuration = 60 * 1000;
  useEffect(() => {
    const startTime = parseInt(localStorage.getItem("countdownStartTime"), 10);
    if (startTime) {
      updateCountdown(startTime);
    }
  }, []);

  const startCountdown = () => {
    const startTime = Date.now();
    localStorage.setItem("countdownStartTime", startTime);
    updateCountdown(startTime);
  };

  const updateCountdown = (startTime) => {
    const elapsedTime = Date.now() - startTime;
    const remainingTime = countdownDuration - elapsedTime;

    if (remainingTime > 0) {
      const secondsRemaining = Math.ceil(remainingTime / 1000);
      setResentButtonText(`${secondsRemaining}s Resend`);
      setVerifyButtonIsDisable(true);
      setCodeInputIsDisable(true);

      setTimeout(() => updateCountdown(startTime), 1000);
    } else {
      setVerifyButtonIsDisable(false);
      setCodeInputIsDisable(false);
      setResentButtonText("Resend");
      localStorage.removeItem("countdownStartTime");
    }
  };

  const handleSubmit = async () => {
    setSpinnerVisible(true);
    setErrorNote("");
    const email = workingEmail.current.value;
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(email) && email !== "") {
      const submitMail = workingEmail?.current?.value;
      const companyDomain = submitMail?.split("@")[1];
      setCompanyName(`https://www.${companyDomain}`);

      if (resentEmailCounter === 3) {
        setErrorNote(
          "Email Verification Failed 3 Times, Please try again later."
        );
        setResentEmailCounter(1);
        setVerifyButtonIsDisable(true);
        setCodeInputIsDisable(true);

        for (let i = 60; i > 0; i--) {
          ((i) => {
            setTimeout(() => {
              setResentButtonText(`${i}s Resend`);
              localStorage.setItem("resentCountDown", i);
              if (i === 1) {
                // After the last second, reset the states
                setVerifyButtonIsDisable(false);
                setCodeInputIsDisable(false);
                setResentButtonText("Resend");
              }
            }, (60 - i) * 1000);
          })(i);
        }
        startCountdown();
      } else {
        const restrictedMailsRes = await getRestrictedMails();
        const restrictedMails = restrictedMailsRes.listOfRestrictedMails;
        // const restrictedMails = ["gmail.com", "yahoo.com", "icloud.com"];

        let isRestricted = restrictedMails.some((domain) =>
          submitMail?.endsWith(domain)
        );

        if (!isRestricted) {
          try {
            const response = await fetch(
              `${process.env.REACT_APP_CONNECTION_PROTOCOL}://${process.env.REACT_APP_Backend_IP}:${process.env.REACT_APP_Backend_Port}/register`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  email: submitMail,
                }),
              }
            );

            if (!response.ok) {
              setErrorNote("Server Is Busy, Please try again later");
            }

            const data = await response.json();

            if (data.message === "This Email is Already Registered") {
              setErrorNote(
                "This email has reviewed this month. You can review again next month."
              );
            }
            if (data.message === "Email Sent For Verification") {
              changeVisibility("verificationField", "block");

              setSubmitButtonType("codeVerify");
              setErrorNote(
                "Please Check your inbox, we sent you a verification code"
              );
              setEmailInputIsDisable(true);
              setResentEmailCounter(resentEmailCounter + 1);
            }
          } catch (e) {
            setErrorNote("Error while submitting mail, try again later");
          }
        } else {
          setErrorNote("Enter a work email:");
        }
      }
    } else {
      setErrorNote("Please submit a correct email");
    }
    setSpinnerVisible(false);
  };

  const verifySubmit = async () => {
    setSpinnerVisible(true);
    if (verificationCode?.current?.value !== "") {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_CONNECTION_PROTOCOL}://${process.env.REACT_APP_Backend_IP}:${process.env.REACT_APP_Backend_Port}/codeVerification`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              verifiedCode: verificationCode?.current?.value,
            }),
          }
        );

        const resInJson = await res.json();
        if (resInJson?.message === "Congrats Email Verified") {
          changeVisibility("errorNote", "none");
          changeVisibility("successNote", "inline-block");

          setSuccessNote(resInJson?.message);
          setCodeInputIsDisable(true);

          if (resInJson?.isAlreadyExist) {
            setCompanyName(resInJson?.companyName);
          }

          const submitMail = workingEmail?.current?.value;
          const companyDomain = companyName;

          if (companyDomain !== "") {
            let dotComTest = companyDomain.includes(".com");
            let httpsTest = companyDomain.includes("https://");
            let httpTest = companyDomain.includes("http://");

            if (dotComTest && (httpsTest || httpTest)) {
              const res = await fetch(
                `${process.env.REACT_APP_CONNECTION_PROTOCOL}://${process.env.REACT_APP_Backend_IP}:${process.env.REACT_APP_Backend_Port}/registerUser`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    email: submitMail,
                    company: companyDomain,
                  }),
                }
              );

              const resInJson = await res.json();

              if (resInJson?.message === "User Saved Successfully") {
                localStorage.setItem("jwtToken", resInJson?.jwtToken);
                navigate("/review");
              }
            } else {
              changeVisibility("successNote", "none");
              changeVisibility("errorNote", "inline-block");
              setErrorNote("Please ensure the domain is in the correct format");
            }
          } else {
            changeVisibility("successNote", "none");
            changeVisibility("errorNote", "inline-block");
            setErrorNote("Please enter your company domain.");
          }
        } else {
          setErrorNote(resInJson?.message);
        }
      } catch (e) {
        console.log("Error while Code Verification");
      }
    } else {
      setErrorNote(
        "Please enter the verification code sent to your provided email address."
      );
    }
    setSpinnerVisible(false);
  };

  return (
    <>
      <Button onClick={handleShow}>{buttonText}</Button>

      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        style={{ width: "100%" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{buttonText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.ReviewPopBody}>
            <div
              style={{
                margin: "20px 0px",
              }}
            >
              <p>Enter a work email:</p>
              <input
                disabled={emailInputIsDisable}
                ref={workingEmail}
                placeholder="i.e: herry@truspo.com"
              />
            </div>
            <div
              id="verificationField"
              style={{ margin: "20px 0px", display: "none" }}
            >
              <p>Enter Verification Code:</p>
              <input
                disabled={codeInputIsDisable}
                ref={verificationCode}
                placeholder="Enter Verification Code"
              />
            </div>

            {submitButtonType === "email" ? (
              <Button
                id="submitButton"
                variant="primary"
                onClick={handleSubmit}
                disabled={SpinnerVisible}
              >
                {SpinnerVisible ? (
                  <Spinner
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "5px",
                    }}
                    animation="border"
                    variant="light"
                  />
                ) : (
                  <></>
                )}
                Submit
              </Button>
            ) : (
              <></>
            )}

            {submitButtonType === "codeVerify" ? (
              <>
                <Button
                  style={{ margin: "0px 5px" }}
                  variant="primary"
                  onClick={verifySubmit}
                  disabled={verifyButtonIsDisable || SpinnerVisible}
                >
                  {SpinnerVisible ? (
                    <Spinner
                      style={{
                        height: "20px",
                        width: "20px",
                        marginRight: "5px",
                      }}
                      animation="border"
                      variant="light"
                    />
                  ) : (
                    <></>
                  )}
                  Verify
                </Button>
                <Button
                  style={{ margin: "0px 5px" }}
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={verifyButtonIsDisable || SpinnerVisible}
                >
                  {SpinnerVisible ? (
                    <Spinner
                      style={{
                        height: "20px",
                        width: "20px",
                        marginRight: "5px",
                      }}
                      animation="border"
                      variant="light"
                    />
                  ) : (
                    <></>
                  )}
                  {resentButtonText}
                </Button>
              </>
            ) : (
              <></>
            )}

            <span
              id="errorNote"
              style={{
                color: "red",
                margin: "0px 20px",
                display: "inline-block",
                fontWeight: "800",
              }}
            >
              {errorNote}
            </span>
            <span
              id="successNote"
              style={{
                color: "green",
                margin: "0px 20px",
                display: "none",
                fontWeight: "800",
              }}
            >
              {successNote}
            </span>
          </div>
          <FAQS widthSize="80%" FaqData={faqData} />
        </Modal.Body>
      </Modal>
    </>
  );
}
