// import React, { useState, useEffect, useRef } from "react";
// import { Container, Row, Col, Button, Offcanvas } from "react-bootstrap";
// import styles from "./style/header.module.css";
// import ReviewPopup from "./popups/reviewPopup";
// import { useNavigate } from "react-router-dom";
// import { getHeaderData } from "../sanity_api/sanity_apis";

// export default function Header({ FaqData, buttonVisible }) {
//   let navigate = useNavigate();

//   const [showDrawer, setShowDrawer] = useState(false);
//   const [buttonText, setButtonText] = useState("");
//   const [companylogo, setCompanylogo] = useState();
//   const companyNameSearch = useRef("useRef");
//   const debounceTimeout = useRef(null);

//   const [branchFetchCompanies, setBranchFetchCompanies] = useState();

//   const toggleDrawer = () => setShowDrawer((prev) => !prev);

//   useEffect(() => {
//     async function callSanityAPI() {
//       let res = await getHeaderData();

//       setButtonText(res?.headerbuttontext);
//       setCompanylogo(res?.companylogo);
//     }
//     callSanityAPI();
//   }, []);

//   const branchFetchCall = async () => {
//     const companyName = companyNameSearch?.current?.value;
//     if (companyName.trim() === "") {
//       const searchPopup = document.getElementById("searchPopup");
//       searchPopup.style.display = "none";
//       setBranchFetchCompanies(null); // Clear the results if the input is empty
//       return;
//     }
//     const apiReq = await fetch(
//       `https://api.brandfetch.io/v2/search/${companyName}`
//     );
//     const resInJson = await apiReq.json();
//     setBranchFetchCompanies(resInJson ? resInJson : null);

//     console.log("Company Res", resInJson);

//     const searchPopup = document.getElementById("searchPopup");
//     searchPopup.style.display = "block";
//   };

//   const handleChange = () => {
//     if (debounceTimeout.current) {
//       clearTimeout(debounceTimeout.current);
//     }
//     debounceTimeout.current = setTimeout(() => {
//       branchFetchCall();
//     }, 300); // Adjust debounce delay as needed
//   };

//   useEffect(() => {
//     return () => {
//       if (debounceTimeout.current) {
//         clearTimeout(debounceTimeout.current);
//       }
//     };
//   }, []);

//   useEffect(() => {
//     if (branchFetchCompanies) {
//       console.log("Updated Company Res", branchFetchCompanies);
//     }
//   }, [branchFetchCompanies]);

//   return (
//     <div className={styles.headerMain}>
//       <Container>
//         <Row>
//           <Col lg={3} md={3} sm={9} xs={9}>
//             <div className={styles.headerLogo}>
//               <img
//                 onClick={() => {
//                   navigate("/");
//                 }}
//                 src={companylogo}
//                 alt="Truspo Logo"
//                 className={styles.headerLogoObj}
//               />
//             </div>
//           </Col>
//           <Col lg={9} md={9} sm={3} xs={3} className="d-lg-none d-md-none">
//             <div className={styles.headerBtn}>
//               <Button variant="outline-dark" onClick={toggleDrawer}>
//                 <span class="material-symbols-outlined">menu</span>
//               </Button>
//             </div>
//           </Col>
//           <Col lg={2} md={2} className="d-none d-lg-block d-md-block">
//             <div className={styles.headerSearchCompanies}>
//               <div className={styles.inputWithIcon}>
//                 <input
//                   onChange={() => {
//                     handleChange();
//                   }}
//                   ref={companyNameSearch}
//                   placeholder="Search Companies"
//                 />
//                 <i className={`${styles.fas} fa fa-building`}></i>
//               </div>
//               <div className={styles.searchPopup} id="searchPopup">
//                 {branchFetchCompanies ? (
//                   <>
//                     {branchFetchCompanies.map((i) => {
//                       return (
//                         <div
//                           onClick={() => {
//                             navigate(`/review/${i?.domain}`);
//                             window.location.reload();
//                           }}
//                           className={styles.popupItem}
//                         >
//                           <div>
//                             <img src={i?.icon} alt="company Icon" />
//                             <span>{i?.name}</span>
//                           </div>
//                           <div>
//                             <p>{i?.domain}</p>
//                           </div>
//                         </div>
//                       );
//                     })}
//                   </>
//                 ) : (
//                   <></>
//                 )}
//               </div>
//             </div>
//           </Col>
//           <Col lg={2} md={2} className="d-none d-lg-block d-md-block">
//             <div className={styles.headerCompaniesReviews}>
//               <h5>Companie Reviews</h5>
//             </div>
//           </Col>
//           <Col lg={2} md={2} className="d-none d-lg-block d-md-block">
//             <div className={styles.headerHowItWorks}>
//               <h5>
//                 <a href="#howitworks">How it Works</a>
//               </h5>
//             </div>
//           </Col>
//           <Col lg={3} md={3} className="d-none d-lg-block d-md-block">
//             <div className={styles.headerBtn}>
//               {buttonVisible ? (
//                 <ReviewPopup buttonText={buttonText} faqData={FaqData} />
//               ) : (
//                 <></>
//               )}
//             </div>
//           </Col>
//         </Row>
//       </Container>

//       {/* Offcanvas for small screens */}
//       <Offcanvas
//         show={showDrawer}
//         onHide={() => setShowDrawer(false)}
//         placement="end"
//         className="d-lg-none d-md-none"
//       >
//         <Offcanvas.Header closeButton>
//           <Offcanvas.Title></Offcanvas.Title>
//         </Offcanvas.Header>
//         <Offcanvas.Body>
//           <div className={styles.offcanvasContent}>
//             <div
//               className={styles.headerSearchCompanies}
//               style={{ justifyContent: "left", margin: "10px 0px 30px 0px" }}
//             >
//               <div className={styles.inputWithIcon}>
//                 <input placeholder="Search Companies" />
//                 <i className={`${styles.fas} fa fa-building`}></i>
//               </div>
//             </div>
//             <div
//               className={styles.headerCompaniesReviews}
//               style={{ justifyContent: "left", margin: "10px 0px" }}
//             >
//               <h5>Companie Reviews</h5>
//             </div>
//             <div
//               className={styles.headerHowItWorks}
//               style={{ justifyContent: "left", margin: "10px 0px" }}
//             >
//               <h5>How it Works</h5>
//             </div>
//             <div
//               className={styles.headerBtn}
//               style={{ justifyContent: "left", margin: "10px 0px" }}
//             >
//               <ReviewPopup buttonText={buttonText} faqData={FaqData} />
//             </div>
//           </div>
//         </Offcanvas.Body>
//       </Offcanvas>
//     </div>
//   );
// }

import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Button, Offcanvas } from "react-bootstrap";
import styles from "./style/header.module.css";
import ReviewPopup from "./popups/reviewPopup";
import { useNavigate } from "react-router-dom";
import { getHeaderData } from "../sanity_api/sanity_apis";

export default function Header({ FaqData, buttonVisible }) {
  let navigate = useNavigate();

  const [showDrawer, setShowDrawer] = useState(false);
  const [buttonText, setButtonText] = useState("");
  const [companylogo, setCompanylogo] = useState();
  const companyNameSearchDesktop = useRef(null);
  const companyNameSearchMobile = useRef(null);
  const debounceTimeout = useRef(null);
  const [branchFetchCompanies, setBranchFetchCompanies] = useState();

  const toggleDrawer = () => setShowDrawer((prev) => !prev);

  useEffect(() => {
    async function callSanityAPI() {
      let res = await getHeaderData();
      setButtonText(res?.headerbuttontext);
      setCompanylogo(res?.companylogo);
    }
    callSanityAPI();
  }, []);

  const branchFetchCall = async (searchRef) => {
    const companyName = searchRef?.current?.value;
    if (companyName.trim() === "") {
      const searchPopup = document.getElementById("searchPopup");
      searchPopup.style.display = "none";
      setBranchFetchCompanies(null); // Clear the results if the input is empty
      return;
    }
    const apiReq = await fetch(
      `https://api.brandfetch.io/v2/search/${companyName}`
    );
    const resInJson = await apiReq.json();
    setBranchFetchCompanies(resInJson ? resInJson : null);

    console.log("Company Res", resInJson);

    const searchPopup = document.getElementById("searchPopup");
    searchPopup.style.display = "block";
  };

  const handleChange = (searchRef) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      branchFetchCall(searchRef);
    }, 300); // Adjust debounce delay as needed
  };

  useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    if (branchFetchCompanies) {
      console.log("Updated Company Res", branchFetchCompanies);
    }
  }, [branchFetchCompanies]);

  return (
    <div className={styles.headerMain}>
      <Container>
        <Row>
          <Col lg={3} md={3} sm={9} xs={9}>
            <div className={styles.headerLogo}>
              <img
                onClick={() => {
                  navigate("/");
                }}
                src={companylogo}
                alt="Truspo Logo"
                className={styles.headerLogoObj}
              />
            </div>
          </Col>
          <Col lg={9} md={9} sm={3} xs={3} className="d-lg-none d-md-none">
            <div className={styles.headerBtn}>
              <Button variant="outline-dark" onClick={toggleDrawer}>
                <span class="material-symbols-outlined">menu</span>
              </Button>
            </div>
          </Col>
          <Col lg={6} md={6} className="d-none d-lg-block d-md-block">
            <div className={styles.headerSearchCompanies}>
              <div className={styles.inputWithIcon}>
                <input
                  onChange={() => {
                    handleChange(companyNameSearchDesktop);
                  }}
                  ref={companyNameSearchDesktop}
                  placeholder="Search Companies"
                />
                <i className={`${styles.fas} fa fa-building`}></i>
              </div>
              <div className={styles.searchPopup} id="searchPopup">
                {branchFetchCompanies ? (
                  <>
                    {branchFetchCompanies.map((i) => {
                      return (
                        <div
                          onClick={() => {
                            navigate(`/review/${i?.domain}`);
                            window.location.reload();
                          }}
                          className={styles.popupItem}
                        >
                          <div>
                            <img src={i?.icon} alt="company Icon" />
                            <span>{i?.name}</span>
                          </div>
                          <div>
                            <p>{i?.domain}</p>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Col>
          <Col lg={3} md={3} className="d-none d-lg-block d-md-block">
            <div className={styles.headerBtn}>
              {buttonVisible ? (
                <ReviewPopup buttonText={buttonText} faqData={FaqData} />
              ) : (
                <></>
              )}
            </div>
          </Col>
        </Row>
      </Container>

      {/* Offcanvas for small screens */}
      <Offcanvas
        show={showDrawer}
        onHide={() => setShowDrawer(false)}
        placement="end"
        className="d-lg-none d-md-none"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className={styles.offcanvasContent}>
            <div
              className={styles.headerSearchCompanies}
              style={{
                alignItems: "baseline",
                margin: "10px 0px 30px 0px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className={styles.inputWithIcon}>
                <input
                  onChange={() => {
                    handleChange(companyNameSearchMobile);
                  }}
                  ref={companyNameSearchMobile}
                  placeholder="Search Companies"
                />
                <i className={`${styles.fas} fa fa-building`}></i>
              </div>

              {/* Render the same search results in mobile view */}
              <div className={styles.searchPopupMobile}>
                {branchFetchCompanies ? (
                  <>
                    {branchFetchCompanies.map((i) => {
                      return (
                        <div
                          onClick={() => {
                            navigate(`/review/${i?.domain}`);
                            window.location.reload();
                          }}
                          className={styles.popupItem}
                        >
                          <div>
                            <img src={i?.icon} alt="company Icon" />
                            <span>{i?.name}</span>
                          </div>
                          {/* <div>
                            <p>{i?.domain}</p>
                          </div> */}
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div
              className={styles.headerCompaniesReviews}
              style={{ justifyContent: "left", margin: "10px 0px" }}
            >
              <h5>Companie Reviews</h5>
            </div>
            <div
              className={styles.headerHowItWorks}
              style={{ justifyContent: "left", margin: "10px 0px" }}
            >
              <h5>How it Works</h5>
            </div>
            <div
              className={styles.headerBtn}
              style={{ justifyContent: "left", margin: "10px 0px" }}
            >
              <ReviewPopup buttonText={buttonText} faqData={FaqData} />
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}
