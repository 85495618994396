import Modal from "react-bootstrap/Modal";

export default function SubmitReviewPopup({
  congratsPopupShow,
  setCongratsPopupShow,
}) {
  const handleClose = () => {
    setCongratsPopupShow(false);
  };

  return (
    <>
      <Modal
        size="lg"
        show={congratsPopupShow}
        onHide={handleClose}
        style={{ width: "100%" }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <div style={{ padding: "40px" }}>
          <h2>Congratations !!</h2>
          <p>
            Your Review is submitted, Now You can review again after 30 days.
          </p>
        </div>
      </Modal>
    </>
  );
}
