import React, { useState, useEffect } from "react";
import { Button, Modal, Container } from "react-bootstrap";
import styles from "../style/admin.module.css";

export function MyVerticallyCenteredModal(props) {
  const [userReviewHistory, setUserReviewHistory] = useState();
  const userdetail = props?.userdetail;
  useEffect(() => {
    async function FetchUserDetail() {
      const res = await fetch(
        `${process.env.REACT_APP_CONNECTION_PROTOCOL}://${process.env.REACT_APP_Backend_IP}:${process.env.REACT_APP_Backend_Port}/fetchReviewerDetail?userId=${userdetail?.userId}`
      );
      const resInJson = await res.json();
      console.log("DOORI", resInJson);
      setUserReviewHistory(resInJson?.record);
    }

    if (userdetail?.userId) {
      FetchUserDetail();
    }
  }, [userdetail?.userId]);
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {userdetail.email}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.UserPopupMain}>
          <Container>
            <table width={"100%"}>
              <thead>
                <tr>
                  <td>
                    <b>UserId</b>
                  </td>
                  <td>
                    <b>Company</b>
                  </td>
                  <td>
                    <b>Last Review Date</b>
                  </td>
                  <td>
                    <b>Total Reviews</b>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{userdetail?.userId}</td>
                  <td>{userdetail?.company}</td>
                  <td>{formatDate(userdetail?.reviewDate)}</td>
                  <td>{userReviewHistory ? userReviewHistory?.length : ""}</td>
                </tr>
              </tbody>
            </table>

            <h4>Review History</h4>

            <table
              width={"100%"}
              style={{
                boxShadow:
                  "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                borderRadius: "8px",
                height: "200px",
                overflowY: "scroll",
              }}
            >
              <thead>
                <tr>
                  <td>
                    <b>Date</b>
                  </td>
                  <td>
                    <b>Average Score</b>
                  </td>
                  <td>
                    <b>Happniess</b>
                  </td>
                  <td>
                    <b>Recommend</b>
                  </td>
                  <td>
                    <b>Future</b>
                  </td>
                </tr>
              </thead>

              {userReviewHistory ? (
                <tbody>
                  {userReviewHistory.map((rec) => {
                    return (
                      <tr>
                        <td>{formatDate(rec?.date)}</td>
                        <td>{rec.avgScorePer}%</td>
                        {rec.happyReviewPer === 100 ? (
                          <td>Happy</td>
                        ) : (
                          <td>Sad</td>
                        )}
                        {rec.recommendReviewPer === 100 ? (
                          <td>Happy</td>
                        ) : (
                          <td>Sad</td>
                        )}
                        {rec.futureReviewPer === 100 ? (
                          <td>Happy</td>
                        ) : (
                          <td>Sad</td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <></>
              )}
            </table>
          </Container>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default function UserPopup({ userdetail }) {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        View
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        userdetail={userdetail}
      />
    </>
  );
}
