import { useState, useEffect } from "react";

export default function SiteMapPage() {
  const [sitemap, setSitemap] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchD = async () => {
      try {
        const sitempas = await fetch(`${process.env.REACT_APP_CONNECTION_PROTOCOL}://${process.env.REACT_APP_Backend_IP}:${process.env.REACT_APP_Backend_Port}/sitemap`, {
          headers: {
            Accept: "application/xml",
          },
        });
        if (sitempas.ok) {
          const xmlText = await sitempas.text();

          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(xmlText, "application/xml");

          // Extract <loc> values from the XML
          const urls = Array.from(xmlDoc.getElementsByTagName("loc")).map(
            (loc) => loc.textContent
          );
          setSitemap(urls);
        }
      } catch (e) {
        console.log("ERROR ON SITEMAP Fetching", e);
        setError(e);
      }
    };
    fetchD();
  }, []);

  return (
    <div>
      {error && <p>{error}</p>}
      {sitemap ? (
        <ul>
          <h1>Sitemap</h1>
          {sitemap.map((url, index) => (
            <li key={index}>
              <a href={url}>{url}</a>
            </li>
          ))}
        </ul>
      ) : (
        <p>Loading sitemap...</p>
      )}
    </div>
  );
}
