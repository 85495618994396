import Footer from "../components/footer";
import Header from "../components/header";
import styles from "./style/review.module.css";
import { useEffect, useState, useRef } from "react";
import { Row, Col, Container } from "react-bootstrap";
import ReviewLogo from "../images/reviewLogo.png";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { getFaqSection } from "../sanity_api/sanity_apis";
import SubmitReviewPopup from "../components/popups/submitReviewPopup";

const CircularProgress = ({ progress }) => {
  const circumference = 2 * Math.PI * 45;
  const offset = circumference - (progress / 100) * circumference;

  return (
    <svg width="220px" height="220px" viewBox="0 0 100 100">
      <circle
        cx="50"
        cy="50"
        r="45"
        stroke="#FCF8F7"
        strokeWidth="5"
        fill="none"
      />
      <circle
        cx="50"
        cy="50"
        r="45"
        stroke="#f1642b"
        strokeWidth="5"
        fill="none"
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        style={{ transition: "stroke-dashoffset 0.5s ease" }}
      />
      <text
        x="50"
        y="50"
        textAnchor="middle"
        dy="0.3em"
        fontSize="20"
      >{`${progress}%`}</text>
    </svg>
  );
};

export default function Reviewer() {
  const navigate = useNavigate();

  const bannerRef = useRef(null);
  const [happyReview, setHappyReview] = useState();
  const [recommendReview, setRecommendReview] = useState();
  const [futureReview, setFutureReview] = useState();
  const [congratsPopupShow, setCongratsPopupShow] = useState(false);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [truspoFaq, setTruspoFaq] = useState();
  const [ErrorMessage, setErrorMessage] = useState("");
  const [avgScore, setAvgScore] = useState(0);
  const [submitButtonVisibility, setSubmitButtonVisibility] = useState(true);

  let jwtToken = localStorage.getItem("jwtToken");

  useEffect(() => {
    async function CallSanityApi() {
      let truspoFaqData = await getFaqSection();

      // Truspo FAQ Sorting Before Saving Into State
      const sortedTruspoFaqData = truspoFaqData?.sort((a, b) => {
        let order = Array.from(
          { length: truspoFaqData.length },
          (v, i) => i + 1
        );
        return order.indexOf(a.truspoFaqRank) - order.indexOf(b.truspoFaqRank);
      });
      setTruspoFaq(sortedTruspoFaqData);
    }
    CallSanityApi();
    if (jwtToken) {
      let temp = jwtDecode(jwtToken);
      setIsAuthenticated(temp);
    } else {
      navigate("/");
    }
  }, [jwtToken, navigate]);

  useEffect(() => {
    const banner = bannerRef.current;

    if (banner && isAuthenticated?.companyMetadata?.image) {
      console.log("banner", isAuthenticated);

      banner.style.backgroundImage = `url("${isAuthenticated?.companyMetadata?.image}")`;
    }
  }, [isAuthenticated]);

  async function onSubmit() {
    if (
      happyReview === undefined ||
      recommendReview === undefined ||
      futureReview === undefined
    ) {
      setErrorMessage("Please Answer All Questions !!");
    } else {
      setErrorMessage("");
      let tempAvgScore = 0;
      if (happyReview) {
        tempAvgScore += 34;
      }
      if (recommendReview) {
        tempAvgScore += 33;
      }
      if (futureReview) {
        tempAvgScore += 33;
      }
      setAvgScore(Number(tempAvgScore));

      let postReq = await fetch(
        `${process.env.REACT_APP_CONNECTION_PROTOCOL}://${process.env.REACT_APP_Backend_IP}:${process.env.REACT_APP_Backend_Port}/submitReview`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: isAuthenticated?.userId,
            company: isAuthenticated?.company,
            companyDomain: isAuthenticated?.companyDomain,
            companyMetadata: isAuthenticated?.companyMetadata,
            avgScorePer: tempAvgScore ? tempAvgScore : 0,
            happyReviewPer: happyReview ? 100 : 0,
            recommendReviewPer: recommendReview ? 100 : 0,
            futureReviewPer: futureReview ? 100 : 0,
          }),
        }
      );
      let postReqJson = await postReq.json();
      if (postReqJson.message === "Review Saved Successfully") {
        setSubmitButtonVisibility(false);
        setErrorMessage("");
        setCongratsPopupShow(true);
        setTimeout(() => {
          localStorage.removeItem("jwtToken");
          console.log("talha", postReqJson.branchFetchDomain);
          navigate(`/review/${postReqJson.branchFetchDomain}`);
          // window.location.reload();
        }, 1000);
      }
    }
  }

  useEffect(() => {
    if (
      happyReview !== undefined ||
      recommendReview !== undefined ||
      futureReview !== undefined
    ) {
      let tempAvgScore = 0;

      if (happyReview) {
        tempAvgScore += 34;
      }
      if (recommendReview) {
        tempAvgScore += 33;
      }
      if (futureReview) {
        tempAvgScore += 33;
      }
      setAvgScore(Number(tempAvgScore));
    }
  }, [happyReview, recommendReview, futureReview]);

  return (
    <>
      <Header FaqData={truspoFaq} buttonVisible={false} />
      {isAuthenticated ? (
        <div className={styles.reviewPageBanner}>
          {/* <div
            id="reviewBanner"
            ref={bannerRef}
            className={styles.reviewLanding}
          >
            <Container>
              <img
                alt="company logo"
                src={
                  isAuthenticated?.companyMetadata?.logo
                    ? isAuthenticated?.companyMetadata?.logo
                    : ReviewLogo
                }
                height={100}
                width={100}
              />
            </Container>
          </div> */}
          <Container>
            <div className={styles.companyOverallReview}>
              {/* Review Header */}
              <Row>
                <Col lg={1} md={1}></Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <div style={{ display: "flex" }}>
                    <div>
                      <img
                        alt="company logo"
                        src={
                          isAuthenticated?.companyMetadata?.logo
                            ? isAuthenticated?.companyMetadata?.logo
                            : ReviewLogo
                        }
                        height={100}
                        className={styles.reviewLandingImg}
                        width={100}
                      />
                    </div>
                    <div>
                      <h2>{isAuthenticated?.companyMetadata?.publisher}</h2>
                      <p>{isAuthenticated?.companyMetadata?.description}</p>
                    </div>
                  </div>
                </Col>
                <Col lg={2} md={2} sm={12} xs={12}>
                  <div className={styles.singleObjOfReview}>
                    <CircularProgress progress={avgScore} />
                  </div>
                </Col>
                <Col lg={2} md={2} sm={12} xs={12}>
                  <div className={styles.singleObjOfReview}>
                    <h3>
                      Average <br /> Score
                    </h3>
                  </div>
                </Col>
                <Col lg={1} md={1} sm={12} xs={12}></Col>
              </Row>

              <div style={{ textAlign: "center", margin: "100px 0px" }}>
                <h2>{`Please Select the following faces to rate ${isAuthenticated?.companyMetadata?.publisher}`}</h2>
                <p>Your answers are annonymous</p>
                <Row>
                  <Col lg={4} md={6} sm={12} xs={12}>
                    <div className={styles.ReviewGivingSection}>
                      <h3>
                        Are you happy at <br />{" "}
                        {`${isAuthenticated?.companyMetadata?.publisher}`} ?
                      </h3>
                      {happyReview === true ? (
                        <span
                          style={{ color: "#f1642b" }}
                          class="material-symbols-outlined"
                          onClick={() => setHappyReview(true)}
                        >
                          mood
                        </span>
                      ) : (
                        <span
                          onClick={() => setHappyReview(true)}
                          class="material-symbols-outlined"
                        >
                          mood
                        </span>
                      )}

                      {happyReview === false ? (
                        <span
                          style={{ color: "#f1642b" }}
                          class="material-symbols-outlined"
                          onClick={() => setHappyReview(false)}
                        >
                          sentiment_dissatisfied
                        </span>
                      ) : (
                        <span
                          onClick={() => setHappyReview(false)}
                          class="material-symbols-outlined"
                        >
                          sentiment_dissatisfied
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col lg={4} md={6} sm={12} xs={12}>
                    <div className={styles.ReviewGivingSection}>
                      <h3>
                        Would you recommend working at{" "}
                        {`${isAuthenticated?.companyMetadata?.publisher}`} ?
                      </h3>
                      {recommendReview === true ? (
                        <span
                          style={{ color: "#f1642b" }}
                          class="material-symbols-outlined"
                          onClick={() => {
                            setRecommendReview(true);
                          }}
                        >
                          mood
                        </span>
                      ) : (
                        <span
                          onClick={() => {
                            setRecommendReview(true);
                          }}
                          class="material-symbols-outlined"
                        >
                          mood
                        </span>
                      )}
                      {recommendReview === false ? (
                        <span
                          style={{ color: "#f1642b" }}
                          class="material-symbols-outlined"
                          onClick={() => {
                            setRecommendReview(false);
                          }}
                        >
                          sentiment_dissatisfied
                        </span>
                      ) : (
                        <span
                          class="material-symbols-outlined"
                          onClick={() => {
                            setRecommendReview(false);
                          }}
                        >
                          sentiment_dissatisfied
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col lg={4} md={6} sm={12} xs={12}>
                    <div className={styles.ReviewGivingSection}>
                      <h3>
                        Are you confident in the future of{" "}
                        {`${isAuthenticated?.companyMetadata?.publisher}`} ?
                      </h3>
                      {futureReview === true ? (
                        <span
                          style={{ color: "#f1642b" }}
                          class="material-symbols-outlined"
                          onClick={() => {
                            setFutureReview(true);
                          }}
                        >
                          mood
                        </span>
                      ) : (
                        <span
                          onClick={() => {
                            setFutureReview(true);
                          }}
                          class="material-symbols-outlined"
                        >
                          mood
                        </span>
                      )}
                      {futureReview === false ? (
                        <span
                          style={{ color: "#f1642b" }}
                          class="material-symbols-outlined"
                          onClick={() => {
                            setFutureReview(false);
                          }}
                        >
                          sentiment_dissatisfied
                        </span>
                      ) : (
                        <span
                          onClick={() => {
                            setFutureReview(false);
                          }}
                          class="material-symbols-outlined"
                        >
                          sentiment_dissatisfied
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>
                <p style={{ color: "red" }}>{ErrorMessage}</p>
                <button
                  style={{
                    display: submitButtonVisibility ? "inline-block" : "none",
                  }}
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  Confirm
                </button>
                <SubmitReviewPopup
                  congratsPopupShow={congratsPopupShow}
                  setCongratsPopupShow={setCongratsPopupShow}
                  buttonText="Confirm"
                />
              </div>

              {/* Review Factors */}
              <div className={styles.reviewSaperateSection}>
                <Container>
                  <Row>
                    <Col lg={4} md={4} sm={6} xs={12}>
                      <div>
                        <CircularProgress progress={happyReview ? 100 : 0} />
                        <h3>Happiness Factor</h3>
                      </div>
                    </Col>
                    <Col lg={4} md={4} sm={6} xs={12}>
                      <div>
                        <CircularProgress
                          progress={recommendReview ? 100 : 0}
                        />
                        <h3>Recomended</h3>
                      </div>
                    </Col>
                    <Col lg={4} md={4} sm={6} xs={12}>
                      <div>
                        <CircularProgress progress={futureReview ? 100 : 0} />
                        <h3>Culture & Direction</h3>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </Container>
        </div>
      ) : (
        <></>
      )}

      <Footer FaqData={truspoFaq} buttonVisible={false} />
    </>
  );
}
