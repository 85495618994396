import Header from "../components/header";
import Footer from "../components/footer";
import { Container } from "react-bootstrap";
import styles from "./style/policypage.module.css";
import { useState, useEffect } from "react";
import { getInformationPage, getFaqSection } from "../sanity_api/sanity_apis";
import PortableText from "@sanity/block-content-to-react";
import { Spinner } from "react-bootstrap";

export default function PolicyPage({ pageName }) {
  const [pageData, setPageData] = useState();
  const [isVisible, setIsVisible] = useState(true);
  const [truspoFaq, setTruspoFaq] = useState();

  useEffect(() => {
    async function CallSanityAPI() {
      let pageData = await getInformationPage(pageName);
      setPageData(pageData);

      let truspoFaqData = await getFaqSection();

      // Truspo FAQ Sorting Before Saving Into State
      const sortedTruspoFaqData = truspoFaqData?.sort((a, b) => {
        let order = Array.from(
          { length: truspoFaqData.length },
          (v, i) => i + 1
        );
        return order.indexOf(a.truspoFaqRank) - order.indexOf(b.truspoFaqRank);
      });
      setTruspoFaq(sortedTruspoFaqData);

      setIsVisible(false);
    }
    CallSanityAPI();
  }, [pageName]);

  // Function to format the date
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <div className={styles.PolicyPageMain}>
      <Header FaqData={truspoFaq}  buttonVisible={true}/>
      {/* PreLoader */}
      <div
        className={styles.overlays}
        style={{ display: isVisible ? "block" : "none" }}
      >
        <div className={styles.overlaysSpinner}>
          <Spinner
            animation="grow"
            id="overlays"
            style={{ color: "#f1642b", height: "100px", width: "100px" }}
          />
        </div>
      </div>
      <Container>
        <div style={{ padding: "80px 0px" }}>
          <h1>{pageData?.pageName}</h1>
          <h5>
            Last Updated:{" "}
            {pageData?._updatedAt ? formatDate(pageData?._updatedAt) : ""}
          </h5>
          <PortableText blocks={pageData?.pageDescription} />
        </div>
      </Container>
      <Footer FaqData={truspoFaq}  buttonVisible={true}/>
    </div>
  );
}
