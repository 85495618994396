import { useEffect, useState, useRef } from "react";
import { Container, Spinner } from "react-bootstrap";
import Footer from "../components/footer";
import Header from "../components/header";
import styles from "./style/review.module.css";

import { Row, Col } from "react-bootstrap";
import ReviewLogo from "../images/reviewLogo.png";
import { getFaqSection } from "../sanity_api/sanity_apis";
import { useParams } from "react-router-dom";

const CircularProgress = ({ progress }) => {
  const circumference = 2 * Math.PI * 45;
  const offset = circumference - (progress / 100) * circumference;

  return (
    <svg width="220px" height="220px" viewBox="0 0 100 100">
      <circle
        cx="50"
        cy="50"
        r="45"
        stroke="#FCF8F7"
        strokeWidth="5"
        fill="none"
      />
      <circle
        cx="50"
        cy="50"
        r="45"
        stroke="#f1642b"
        strokeWidth="5"
        fill="none"
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        style={{ transition: "stroke-dashoffset 0.5s ease" }}
      />
      <text
        x="50"
        y="50"
        textAnchor="middle"
        dy="0.3em"
        fontSize="20"
      >{`${progress}%`}</text>
    </svg>
  );
};

export default function ReviewPage() {
  const bannerRef = useRef(null);
  const { companyname } = useParams();
  const [truspoFaq, setTruspoFaq] = useState();
  const [companyStats, setCompanyStats] = useState();
  const [beTheFirstOne, setBeTheFirstOne] = useState(false);

  const metaDescription = document.querySelector('meta[name="description"]');

  useEffect(() => {
    async function fetchData() {
      // Fetch FAQ Section
      const truspoFaqData = await getFaqSection();
      const sortedTruspoFaqData = truspoFaqData?.sort((a, b) => {
        let order = Array.from(
          { length: truspoFaqData.length },
          (v, i) => i + 1
        );
        return order.indexOf(a.truspoFaqRank) - order.indexOf(b.truspoFaqRank);
      });
      setTruspoFaq(sortedTruspoFaqData);

      // Fetch Company Stats
      const res = await fetch(
        `${process.env.REACT_APP_CONNECTION_PROTOCOL}://${process.env.REACT_APP_Backend_IP}:${process.env.REACT_APP_Backend_Port}/fetchReview?companyDomain=${companyname}`
      );
      const resInJson = await res.json();
      if (resInJson?.noStatsFound === true) {
        setBeTheFirstOne(true);
      }
      setCompanyStats(resInJson.record);
    }
    metaDescription.setAttribute(
      "content",
      "Github: Anonymously Review Your Company"
    );
    fetchData();
  }, []); // Empty dependency array to run effect only on mount

  useEffect(() => {
    const banner = bannerRef.current;

    if (banner && companyStats?.banner) {
      // banner.style.backgroundImage = `url("${companyStats?.banner}")`;
    }
  }, [companyStats]);

  return (
    <>
      <Header FaqData={truspoFaq} buttonVisible={true} />
      {companyStats ? (
        <div className={styles.reviewPageBanner}>
          {/* <div ref={bannerRef} className={styles.reviewLanding}>
            <Container>
            <img
                alt="company logo"
                src={
                  isAuthenticated?.companyMetadata?.logo
                    ? isAuthenticated?.companyMetadata?.logo
                    : ReviewLogo
                }
                height={100}
                width={100}
              />
            </Container>
          </div> */}
          <Container>
            <div className={styles.companyOverallReview}>
              <Row>
                <Col lg={1} md={1}></Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <div style={{ display: "flex" }}>
                    <div>
                      <img
                        alt="Company Logo"
                        src={
                          companyStats?.logo ? companyStats?.logo : ReviewLogo
                        }
                        className={styles.reviewLandingImg}
                        height={100}
                        width={100}
                      />
                    </div>
                    <div>
                      <h2>{companyStats?.company}</h2>
                      <p>{companyStats?.about}</p>
                    </div>
                  </div>
                </Col>
                <Col lg={2} md={2} sm={12} xs={12}>
                  <div className={styles.singleObjOfReview}>
                    <CircularProgress
                      progress={Number(companyStats.averageScore)}
                    />
                  </div>
                </Col>
                <Col lg={2} md={2} sm={12} xs={12}>
                  <div className={styles.singleObjOfReview}>
                    <h3>
                      Average <br /> Score
                    </h3>
                  </div>
                </Col>
                <Col lg={1} md={1} sm={12} xs={12}></Col>
              </Row>
              <div className={styles.reviewSaperateSection}>
                <Container>
                  {beTheFirstOne ? (
                    <div>
                      <h4>
                        No reviews yet. Be the first to share your experience if
                        you work at this company.
                      </h4>
                    </div>
                  ) : (
                    <Row>
                      <Col lg={4} md={4} sm={6} xs={12}>
                        <div>
                          <CircularProgress
                            progress={Number(companyStats?.happinessFactor)}
                          />
                          <h3>Happiness Factor</h3>
                        </div>
                      </Col>
                      <Col lg={4} md={4} sm={6} xs={12}>
                        <div>
                          <CircularProgress
                            progress={Number(companyStats?.recommendFactor)}
                          />
                          <h3>Recomended</h3>
                        </div>
                      </Col>
                      <Col lg={4} md={4} sm={6} xs={12}>
                        <div>
                          <CircularProgress
                            progress={Number(companyStats?.futureFactor)}
                          />
                          <h3>Culture & Direction</h3>
                        </div>
                      </Col>
                    </Row>
                  )}

                  {/* <Row>
                    <Col>
                      <div className={styles.reviewLastBtn}>
                        <ReviewPopup />
                      </div>
                    </Col>
                  </Row> */}
                </Container>
              </div>
            </div>
          </Container>
        </div>
      ) : (
        <>
          {/* PreLoader */}
          <div className={styles.overlays} style={{ display: "block" }}>
            <div className={styles.overlaysSpinner}>
              <Spinner
                animation="grow"
                id="overlays"
                style={{ color: "#f1642b", height: "100px", width: "100px" }}
              />
            </div>
          </div>
        </>
      )}
      <Footer FaqData={truspoFaq} buttonVisible={true} />
    </>
  );
}
