import Header from "../../components/header";
import Footer from "../../components/footer";
import { Container, Row, Col } from "react-bootstrap";
import styles from "../style/blogs.module.css";
import React, { useEffect, useState } from "react";
import {
  getSingleBlogSection,
  getFaqSection,
} from "../../sanity_api/sanity_apis";
import { useParams } from "react-router-dom";
import PortableText from "@sanity/block-content-to-react";
import { sanityClient } from "../../sanityClient";
import imageUrlBuilder from "@sanity/image-url";
import { Spinner } from "react-bootstrap";

export default function SingleBlog() {
  let { blogslug } = useParams();
  const [singleBlog, setSingleBlog] = useState();
  const [blogImage, setBlogImage] = useState();
  const [isVisible, setIsVisible] = useState(true);
  const [truspoFaq, setTruspoFaq] = useState();

  useEffect(() => {
    // Initialize Bootstrap scrollspy
    // const bootstrap = require("bootstrap/dist/js/bootstrap.bundle.min");

    const scrollSpyElement = document.querySelector('[data-bs-spy="scroll"]');
    if (scrollSpyElement) {
      // const bsScrollSpy = new bootstrap.ScrollSpy(scrollSpyElement, {
      //   target: "#list-example",
      // });
    }
  }, []);

  useEffect(() => {
    async function CallSanityApi() {
      let res = await getSingleBlogSection(blogslug);

      const builder = imageUrlBuilder(sanityClient);
      let imageProps = builder.image(res?.blogThumbnail).url();

      setBlogImage(imageProps);
      setSingleBlog(res);

      let truspoFaqData = await getFaqSection();
      //  Truspo FAQ Sorting Before Saving Into State
      const sortedTruspoFaqData = truspoFaqData?.sort((a, b) => {
        let order = Array.from(
          { length: truspoFaqData.length },
          (v, i) => i + 1
        );
        return order.indexOf(a.truspoFaqRank) - order.indexOf(b.truspoFaqRank);
      });
      setTruspoFaq(sortedTruspoFaqData);
      setIsVisible(false);
    }

    CallSanityApi();
  }, [blogslug]);

  return (
    <>
      <Header FaqData={truspoFaq} buttonVisible={true} />
      {/* PreLoader */}
      <div
        className={styles.overlays}
        style={{ display: isVisible ? "block" : "none" }}
      >
        <div className={styles.overlaysSpinner}>
          <Spinner
            animation="grow"
            id="overlays"
            style={{ color: "#f1642b", height: "100px", width: "100px" }}
          />
        </div>
      </div>
      <div className={styles.singleBlogLandingSection}>
        <Row>
          <Col lg={5} md={6} sm={12} xs={12}>
            <div className={styles.singleBlogTitleSection}>
              <h1 style={{ textTransform: "capitalize" }}>
                {singleBlog?.blogTitle}
              </h1>
              {/* <p>
                In today’s fast-paced corporate landscape, fostering open
                communication is essential.
              </p> */}
            </div>
          </Col>
          <Col lg={7} md={6} sm={12} xs={12}>
            <div className={styles.singleBlogImgSection}>
              <img
                alt={blogslug}
                src={blogImage}
                width={"100%"}
                height={"100%"}
              />
            </div>
          </Col>
        </Row>
      </div>
      {/* Scrollpay Section */}
      <div>
        <Container>
          <div style={{ padding: "120px 0px" }}>
            <Row>
              {/* <Col lg={4} md={4} sm={4} xs={4}>
                <div
                  id="list-example"
                  className="list-group"
                  style={{ position: "sticky", top: "0" }}
                >
                  <a
                    className="list-group-item list-group-item-action"
                    href="#list-item-1"
                  >
                    Item 1
                  </a>
                  <a
                    className="list-group-item list-group-item-action"
                    href="#list-item-2"
                  >
                    Item 2
                  </a>
                  <a
                    className="list-group-item list-group-item-action"
                    href="#list-item-3"
                  >
                    Item 3
                  </a>
                  <a
                    className="list-group-item list-group-item-action"
                    href="#list-item-4"
                  >
                    Item 4
                  </a>
                </div>
              </Col> */}
              <Col lg={12} md={12} sm={12} xs={12}>
                {/* <div
                  data-bs-spy="scroll"
                  data-bs-target="#list-example"
                  data-bs-offset="0"
                  className="scrollspy-example"
                  style={{ height: "500px", overflowY: "scroll" }}
                > */}
                <PortableText blocks={singleBlog?.blogDescription} />
                {/* </div> */}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <div className={styles.SubscriptionSection}>
          <div className={styles.Subscription}>
            <p>Stay in the loop</p>
            <h3>Subscribe to our Community</h3>
            <input placeholder="Email" />
            <br />
            <button>Subscribe</button>
          </div>
        </div>
      </div>
      <Footer FaqData={truspoFaq} buttonVisible={true} />
    </>
  );
}
