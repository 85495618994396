import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/homePage";
import PolicyPage from "./pages/informationalPage";
import ContactPage from "./pages/contact";
import BlogsPage from "./pages/blogs/blogsPage";
import SingleBlog from "./pages/blogs/singleBlog";
import LoginPage from "./pages/admin/login";
import Dashboard from "./pages/admin/dashboard";
import ReviewPage from "./pages/reviewPage";
import Reviewer from "./pages/reviewer";
import SiteMapPage from "./pages/sitemap";

// The component where we define all routes
function App() {
  return (
    <Routes>
      {/* User Side */}
      <Route path="/" element={<HomePage />} />
      <Route
        path="/privacy-policy"
        element={<PolicyPage pageName="Privacy Policy" />}
      />
      <Route
        path="/Cookies"
        element={<PolicyPage pageName="Cookies Policy" />}
      />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/blogs" element={<BlogsPage />} />
      <Route path="/blogs/:blogslug" element={<SingleBlog />} />

      {/* Admin Side */}

      <Route path="/login" element={<LoginPage />} />
      <Route path="/dashboard" element={<Dashboard />} />

      {/* Reviewer Side */}
      <Route path="/review" element={<Reviewer />} />
      <Route path="/review/:companyname" element={<ReviewPage />} />

      {/* For SEO */}
      <Route path="/sitemap.xml" element={<SiteMapPage />} />
    </Routes>
  );
}

export default App;
