import { Button, Modal, Container, Row, Col } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import styles from "../style/admin.module.css";

function MyVerticallyCenteredModal(props) {
  const companydetail = props.companydetail;

  const [companyDetailStats, setCompanyDetailStats] = useState();

  useEffect(() => {
    if (props.show) {
      async function fetchCompanyDetailStats() {
        console.log("SHOW PROP", props.show);
        const res = await fetch(
          `${process.env.REACT_APP_CONNECTION_PROTOCOL}://${process.env.REACT_APP_Backend_IP}:${process.env.REACT_APP_Backend_Port}/fetchCompanyDetail?companyDomain=${companydetail.companyName}`
        );
        const resInJson = await res.json();

        setCompanyDetailStats(resInJson.record);
      }
      fetchCompanyDetailStats();
    }
  }, [props.show, companydetail.companyName]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {companydetail.companyName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col lg={4} md={12} sm={12} xs={12}>
              <div className={styles.companyReviewStats}>
                <h4>Total Reviewers</h4>
                <span class="material-symbols-outlined">groups</span>
                <h4>{companyDetailStats?.uniqueCount}</h4>
              </div>
            </Col>
            <Col lg={4} md={12} sm={12} xs={12}>
              <div className={styles.companyReviewStats}>
                <h4>Total Reviews</h4>
                <span class="material-symbols-outlined">groups</span>
                <h4>{companyDetailStats?.allReviews}</h4>
              </div>
            </Col>
            <Col lg={4} md={12} sm={12} xs={12}>
              <div className={styles.companyReviewStats}>
                <h4>Numbers Are Happy</h4>
                <span class="material-symbols-outlined">mood</span>
                <h4>{companyDetailStats?.positiveHappyReview}</h4>
              </div>
            </Col>
            <Col lg={4} md={12} sm={12} xs={12}>
              <div className={styles.companyReviewStats}>
                <h4>Number Are Sad</h4>
                <span class="material-symbols-outlined">
                  sentiment_dissatisfied
                </span>
                <h4>{companyDetailStats?.nagetiveHappyReview}</h4>
              </div>
            </Col>
            <Col lg={4} md={12} sm={12} xs={12}>
              <div className={styles.companyReviewStats}>
                <h4>Number Who Recommeded</h4>
                <span class="material-symbols-outlined">thumb_up</span>
                <h4>{companyDetailStats?.positiveRecommendReview}</h4>
              </div>
            </Col>
            <Col lg={4} md={12} sm={12} xs={12}>
              <div className={styles.companyReviewStats}>
                <h4>Number Who Not Recommended</h4>
                <span class="material-symbols-outlined">thumb_down</span>
                <h4>{companyDetailStats?.nagetiveRecommendReview}</h4>
              </div>
            </Col>
            <Col lg={4} md={12} sm={12} xs={12}>
              <div className={styles.companyReviewStats}>
                <h4>Number Are Future Positive</h4>
                <span class="material-symbols-outlined">trending_up</span>
                <h4>{companyDetailStats?.positiveFutureReview}</h4>
              </div>
            </Col>
            <Col lg={4} md={12} sm={12} xs={12}>
              <div className={styles.companyReviewStats}>
                <h4>Number Are Future Nagetive</h4>
                <span class="material-symbols-outlined">trending_down</span>
                <h4>{companyDetailStats?.nagetiveFutureReview}</h4>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default function CompanyPopup({ companydetail }) {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        View
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        companydetail={companydetail}
      />
    </>
  );
}
