import { useState, useEffect } from "react";
import styles from "./style/faqs.module.css";
const FaqItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="faq-item">
      <div
        className="question"
        onClick={toggleOpen}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={styles2.question}
      >
        <span style={{ color: isHovered ? "#f1642b" : "#3b3a64" }}>
          {question}
        </span>
        <span className="toggle-sign" style={styles2.toggleSign}>
          {isOpen ? "-" : "+"}
        </span>
      </div>
      <div
        className="answer"
        style={isOpen ? { ...styles2.answer, ...styles2.open } : styles2.answer}
      >
        {answer}
      </div>
    </div>
  );
};

export default function FAQS({ widthSize, FaqData }) {
  const [faqListWidth, setFaqListWidth] = useState("50%");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1000) {
        setFaqListWidth("90%");
      } else {
        setFaqListWidth(widthSize);
      }
    };

    // Initial call to set width based on window size
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className={styles.faqSection}>
      <div
        className="faq-list"
        style={{ width: faqListWidth, margin: "20px auto" }}
      >
        <h1>Frequently Asked Questions</h1>

        {FaqData?.map((faq, index) => (
          <FaqItem
            key={index}
            question={faq?.truspoFaqQuestion}
            answer={faq.truspoFaqAnswer}
          />
        ))}
      </div>
    </div>
  );
}

const styles2 = {
  faqItem: {
    marginBottom: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
  question: {
    fontSize: "1.8rem",
    padding: "10px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "left",
    borderBottom: "1px solid #ccc",
  },
  toggleSign: {
    marginLeft: "10px",
    backgroundColor: "#f1642b",
    padding: "0px 12px",
  },
  answer: {
    fontSize: "1.4rem",
    padding: "20px 10px",
    display: "none",
    transition: "max-height 3s ease-out, opacity 3s ease-out",
    overflow: "hidden",
    textAlign: "left",
    fontWeight: 400,
  },
  open: {
    display: "block",
    maxHeight: "500px", // Adjust as necessary
    opacity: "1",
  },
};
