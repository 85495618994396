import { Row, Col, Container, Badge, Pagination } from "react-bootstrap";
import styles from "../style/dashboard.module.css";
import Navbar from "../../components/admin/navbar";
import { PieChart } from "../../components/admin/PieChart";
import { useEffect, useState } from "react";
import UserPopup from "../../components/admin/userPopup";
import CompanyPopup from "../../components/admin/companyPopup";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";

export function DashboardCharts() {
  const [dashboardStats, setDashboardStats] = useState();

  useEffect(() => {
    async function fetchDashboardStats() {
      const res = await fetch(
        `${process.env.REACT_APP_CONNECTION_PROTOCOL}://${process.env.REACT_APP_Backend_IP}:${process.env.REACT_APP_Backend_Port}/fetchDashboardStats`
      );
      const resInJson = await res.json();
      setDashboardStats(resInJson?.data);
    }
    fetchDashboardStats();
  }, []);

  const data = {
    labels: ["Red", "Blue", "Yellow"],
    datasets: [
      {
        label: "My First Dataset",
        data: [300, 50, 100],
        backgroundColor: [
          "rgb(255, 99, 132)",
          "rgb(54, 162, 235)",
          "rgb(255, 205, 86)",
        ],
        hoverOffset: 4,
      },
    ],
  };
  return (
    <div style={{ margin: "60px 0px" }}>
      <Container>
        {dashboardStats ? (
          <Row>
            <Col lg={4} md={6} sm={12} xs={12}>
              <div
                className={styles.chartCard}
                style={{ backgroundColor: "green" }}
              >
                <h3>
                  <b>{dashboardStats?.totalReviews}</b>
                </h3>
                <p>Total Reveiws Submit By Users</p>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <span
                    style={{ fontSize: "2.8rem" }}
                    class="material-symbols-outlined"
                  >
                    group
                  </span>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12} xs={12}>
              <div
                className={styles.chartCard}
                style={{ backgroundColor: "#3b3a64" }}
              >
                <h3>
                  <b>{dashboardStats?.totalReviewers}</b>
                </h3>
                <p>Total Reviewers</p>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <span
                    style={{ fontSize: "2.8rem" }}
                    class="material-symbols-outlined"
                  >
                    group
                  </span>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12} xs={12}>
              <div
                className={styles.chartCard}
                style={{ backgroundColor: "#f1642b" }}
              >
                <h3>
                  <b>{dashboardStats?.totalCompanies}</b>
                </h3>
                <p>Total Companies</p>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <span
                    style={{ fontSize: "2.8rem" }}
                    class="material-symbols-outlined"
                  >
                    group
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          <></>
        )}

        <div className={styles.PieCharts}>
          <Row>
            <Col lg={6} md={6} sm={12} xs={12}>
              <div className={styles.singlePieChart}>
                <PieChart data={data} />
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <div className={styles.singlePieChart}>
                <PieChart data={data} />
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <div className={styles.singlePieChart}>
                <PieChart data={data} />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export function UserManagment() {
  const [userRecords, setUserRecords] = useState();

  useEffect(() => {
    async function FetchUserRecords() {
      const res = await fetch(
        `${process.env.REACT_APP_CONNECTION_PROTOCOL}://${process.env.REACT_APP_Backend_IP}:${process.env.REACT_APP_Backend_Port}/fetchAllReviwers`
      );
      const resInJson = await res.json();
      setUserRecords(resInJson?.record);
    }
    FetchUserRecords();
  }, []);

  const formatDate = (isoStirng) => {
    const date = new Date(isoStirng);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    return `${day}-${month}-${year}`;
  };

  return (
    <>
      {userRecords ? (
        <div className={styles.userManagmentMain}>
          <Container>
            <table width={"100%"}>
              <thead>
                <tr>
                  <td>
                    <b>UserId</b>
                  </td>
                  <td>
                    <b>Email</b>
                  </td>
                  <td>
                    <b>Company</b>
                  </td>
                  <td>
                    <b>Last Review Date</b>
                  </td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                {userRecords.map((record) => {
                  return (
                    <tr>
                      <td>{record?.userId}</td>
                      <td>{record?.email}</td>
                      <td>{record?.company}</td>
                      <td>{formatDate(record?.lastReviewDate)}</td>
                      <td>
                        <UserPopup
                          userdetail={{
                            userId: record?.userId,
                            email: record?.email,
                            company: record?.company,
                            reviewDate: record?.lastReviewDate,
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Container>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export function Companies() {
  const [companyRecord, setCompanyRecord] = useState();

  useEffect(() => {
    async function fetchAllCompanies() {
      const res = await fetch(
        `${process.env.REACT_APP_CONNECTION_PROTOCOL}://${process.env.REACT_APP_Backend_IP}:${process.env.REACT_APP_Backend_Port}/fetchAllCompanies`
      );
      const resInJson = await res.json();

      setCompanyRecord(resInJson.record);
    }

    fetchAllCompanies();
  }, []);

  return (
    <>
      <div className={styles.userManagmentMain}>
        <Container>
          <table width={"100%"}>
            <thead>
              <tr>
                <td>
                  <b>Company Name</b>
                </td>
                <td>
                  <b>Company Domain</b>
                </td>
                <td>Action</td>
              </tr>
            </thead>
            {companyRecord ? (
              <tbody>
                {companyRecord?.map((company) => {
                  return (
                    <tr>
                      <td>{company?.companyName}</td>
                      <td>{company?.companyDomain}</td>
                      <td>
                        <CompanyPopup
                          companydetail={{
                            companyDomain: company?.companyDomain,
                            companyName: company?.companyName,
                            companyTotalReviewers: 100,
                            NumberOfReviewOnHappiness: 30,
                            NumberOfReviewOnRecommendation: 40,
                            NumberofReviewsOnFuture: 30,
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <></>
            )}
          </table>
        </Container>
      </div>
    </>
  );
}

export function EmailSchedule() {
  const recordsPerPage = 5;
  const [remainingTime, setRemainingTime] = useState();
  const [subscriberList, setSubscriberList] = useState();

  const recentEmailData = [
    {
      name: "Monthly Review Reminder",
      date: "2-July-2024",
      to: "uzair@amazon.com",
      status: "Success",
      review: "Pending",
    },
    {
      name: "Monthly Review Reminder",
      date: "2-June-2024",
      to: "talha@spotify.com",
      status: "Success",
    },
    {
      name: "Monthly Review Reminder",
      date: "2-May-2024",
      to: "ahmad@techcompany.com",
      status: "Success",
    },
    // Add more data as needed
  ];

  function paginate(data, page) {
    const start = (page - 1) * recordsPerPage;
    return data.slice(start, start + recordsPerPage);
  }
  const [recentPage, setRecentPage] = useState(1);

  const totalRecentPages = Math.ceil(recentEmailData.length / recordsPerPage);

  async function CallRemainingTime() {
    const res = await fetch(
      `${process.env.REACT_APP_CONNECTION_PROTOCOL}://${process.env.REACT_APP_Backend_IP}:${process.env.REACT_APP_Backend_Port}/remainingTime`
    );
    const resInJson = await res.json();

    const subsriberRes = await fetch(
      `${process.env.REACT_APP_CONNECTION_PROTOCOL}://${process.env.REACT_APP_Backend_IP}:${process.env.REACT_APP_Backend_Port}/fetchSubscriberDetals`
    );
    const subsriberResInJson = await subsriberRes.json();

    // console.log("RES", resInJson, subsriberResInJson);
    setSubscriberList(subsriberResInJson);
    setRemainingTime(resInJson);
  }
  useEffect(() => {
    CallRemainingTime();
  }, []);

  return (
    <>
      <Container>
        <div className={styles.remainingTimeDev}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h5>Countdown to Next Monthly Client Newsletter</h5>
            <span onClick={CallRemainingTime} class="material-symbols-outlined">
              refresh
            </span>
          </div>
          <h3>
            {remainingTime?.days} Days, {remainingTime?.hours} Hours,{" "}
            {remainingTime?.minutes} Minutes
          </h3>
        </div>

        {/* <div className={styles.UpcommingEmailSchedule}>
          <h3>Upcomming Email Schedule</h3>
          <div>
            <table width="100%">
              <thead>
                <tr>
                  <td>
                    <b>Name</b>
                  </td>
                  <td>
                    <b>Date</b>
                  </td>
                  <td>
                    <b>To</b>
                  </td>
                  <td>
                    <b>Remaining Time</b>
                  </td>
                </tr>
              </thead>
              <tbody>
                {paginate(upcomingEmailData, upcomingPage).map(
                  (item, index) => (
                    <tr key={index} className={styles.emailTr}>
                      <td style={{ padding: "10px 0px" }}>{item.name}</td>
                      <td style={{ padding: "10px 0px" }}>{item.date}</td>
                      <td style={{ padding: "10px 0px" }}>{item.to}</td>
                      <td style={{ padding: "10px 0px" }}>
                        {item.remainingTime}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
          <Pagination>
            {[...Array(totalUpcomingPages).keys()].map((number) => (
              <Pagination.Item
                key={number + 1}
                active={number + 1 === upcomingPage}
                onClick={() => setUpcomingPage(number + 1)}
              >
                {number + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </div> */}
        {/* <hr /> */}
        <div className={styles.subscribedSection}>
          <Row>
            <Col>
              <div className={styles.remainingTimeDev}>
                <h4>Total Subscribed Reviewers</h4>
                <h3>
                  {subscriberList ? subscriberList?.list?.subscriber : "Null"}
                </h3>
              </div>
            </Col>
            <Col>
              <div className={styles.remainingTimeDev}>
                <h4>Total Unsubscribed Reviewers</h4>
                <h3>
                  {subscriberList ? subscriberList?.list?.ubsubscriber : "Null"}
                </h3>
              </div>
            </Col>
          </Row>
        </div>
        <div className={styles.RecentEmailSchedule}>
          <h3>Recent Schedules</h3>
          <div>
            <table width="100%">
              <thead>
                <tr>
                  <td>
                    <b>Name</b>
                  </td>
                  <td>
                    <b>Date</b>
                  </td>
                  <td>
                    <b>To</b>
                  </td>
                  <td>
                    <b>Status</b>
                  </td>
                  <td>
                    <b>Review That Month</b>
                  </td>
                </tr>
              </thead>
              <tbody>
                {paginate(recentEmailData, recentPage).map((item, index) => (
                  <tr key={index} className={styles.emailTr}>
                    <td style={{ padding: "10px 0px" }}>{item.name}</td>
                    <td style={{ padding: "10px 0px" }}>{item.date}</td>
                    <td style={{ padding: "10px 0px" }}>{item.to}</td>
                    <td>
                      <Badge bg="success">{item.status}</Badge>
                    </td>
                    <td>
                      {item.review && <Badge bg="warning">{item.review}</Badge>}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination>
            {[...Array(totalRecentPages).keys()].map((number) => (
              <Pagination.Item
                key={number + 1}
                active={number + 1 === recentPage}
                onClick={() => setRecentPage(number + 1)}
              >
                {number + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </div>
      </Container>
    </>
  );
}

export default function Dashboard() {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState("dashboard");
  const [adminSession, setAdminSession] = useState();

  useEffect(() => {
    let userSession = localStorage.getItem("adminSession");

    if (userSession != null) {
      // Decrypt
      var bytes = CryptoJS.AES.decrypt(
        userSession,
        process.env.REACT_APP_JWT_SECRET
      );
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setAdminSession(decryptedData);
    } else {
      navigate("/login");
    }
  }, []);

  return (
    <>
      {adminSession !== null && adminSession !== undefined ?(
        <div className={styles.dashboardMainSection}>
          <Row>
            <Col lg={3} md={2} sm={1} xs={1}>
              <Navbar setCurrentTab={setCurrentTab} />
            </Col>
            <Col lg={9} md={9} sm={11} xs={11}>
              <div className={styles.dashboardBodySection}>
                <div className={styles.dashboardHeader}>
                  <div>
                    {currentTab === "dashboard" ? <p>Dashboard</p> : <></>}
                    {currentTab === "userManagment" ? (
                      <p>User Management</p>
                    ) : (
                      <></>
                    )}
                    {currentTab === "companies" ? <p>Companies</p> : <></>}
                    {currentTab === "emailSchedule" ? (
                      <p>Email Schedule</p>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div style={{ display: "flex" }}>
                    <span
                      style={{ fontSize: "50px", margin: "0px 10px" }}
                      class="material-symbols-outlined"
                    >
                      account_circle
                    </span>
                    <div>
                      <p>{adminSession.fullname}</p>
                      <p style={{ fontSize: "14px" }}>
                        {adminSession.roleType}
                      </p>
                    </div>
                  </div>
                </div>
                {currentTab === "dashboard" ? <DashboardCharts /> : <></>}
                {currentTab === "userManagment" ? <UserManagment /> : <></>}
                {currentTab === "companies" ? <Companies /> : <></>}
                {currentTab === "emailSchedule" ? <EmailSchedule /> : <></>}
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
